import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/core";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import EntrySelector from './EntrySelector';
import PageSelector from "@bloonde/cms/cms-pages/components/PageSelector";
import EntryFormColsSchema from "./EntryFormColsSchema";
import CustomFormTemplate from "@beeldit/core/templates/CustomFormTemplate";
import LabelEntryFormColsSchema from "./LabelEntryFormColsSchema";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
    className?: string;
  }
  formFilterValues: any
}

interface Context {
  element: any;
  setElement: (element: any) => any;
  backendFormErrors: any;
}

function EntryForm(props: Prop) {
  const { formRef, onSubmit, context, className } = props.formConfig;
  const formFilterValues = props.formFilterValues;

  const { element, setElement, backendFormErrors } =
    useContext<Context>(context);

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

  const translations = {
    label: t('label'),
    label_es: t('label_es'),
    label_en: t('label_en'),
    label_de: t('label_de'),
    blank: t('blank'),
    parent_id: t('parent_id'),
    type: t('type'),
    page_id: t('page_id'),
    order: t('order'),
    url: t('url'),
  };

  let schema: any = {
    type: "object",
    properties: {
      label: {
        type: ['object', 'null'],
        title: '',
        properties: {
          es: {
            type: 'string',
            title: translations.label_es,
          },
          en: {
            type: 'string',
            title: translations.label_en,
          },
          de: {
            type: 'string',
            title: translations.label_de,
          }
        },
      },
      type: {
        type: 'number',
        title: translations.type,
        show: false,
        default: 0,
      },
      page_id: {
        type: ['integer', 'null'],
        title: translations.page_id,
        show: element.type === 0,
      },
      order: {
        type: 'number',
        title: translations.order,
        minimum: 1
      },
      url: {
        type: 'string',
        title: translations.url,
        show: element.type === 1,
      },
      blank: {
        type: 'boolean',
        title: translations.blank,
        show: false,
        default: 0,
      },
      parent_id: {
        type: ['integer', 'null'],
        title: translations.parent_id,
        show: false,
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:classNames": className,
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
    type: {
      "ui:widget": "type-selector",
      "ui:options": {
        "configPath": "types.entries.type",
      }
    },
    page_id: {
      "ui:widget": "page-selector",
    },
    parent_id: {
      "ui:widget": "entry-selector",
      "ui:options": {
        filters: formFilterValues
      }
    },
    colsSchema: EntryFormColsSchema,
    label: {
      colsSchema: LabelEntryFormColsSchema
    }
  };
  const customWidgets = {
    ...widgets,
    ...{
      "entry-selector": EntrySelector,
      "page-selector": PageSelector
    },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  const processSchemaFunction = useProcessSchema();

  useEffect(() => {
    setFinalSchema(processSchemaFunction(schema, element));
  }, [element]);

  const formTemplate = CustomFormTemplate;

  return (
    <JSONSchemaForm
      noHtml5Validate
      showErrorList={false}
      ref={formRef}
      templates={{
        ObjectFieldTemplate: formTemplate,
      }}
      schema={finalSchema}
      uiSchema={uiSchema}
      widgets={customWidgets}
      formData={element}
      validator={validator}
      onChange={handleChange}
      onSubmit={onSubmit}
      onError={log("errors")}
      extraErrors={backendFormErrors}
    />
  );
}

export default EntryForm;
